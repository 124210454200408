@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 'bold';
	font-style: normal;
}

@keyframes ldsRipple {
	0% {
		top: 20px;
		left: 20px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 40px;
		height: 40px;
		opacity: 0;
	}
}

@keyframes ldsRippleTransaction {
	0% {
		top: 60px;
		left: 60px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 120px;
		height: 120px;
		opacity: 0;
	}
}

.spicy-enter {
	opacity: .01;
	transform: scaleX(0) translateY(100px);
}

.spicy-enter.spicy-enter-active {
	opacity: 1;
	transform: scaleX(1) translateY(0);
	transition: all 500ms ease-in-out;
}

.spicy-leave {
	opacity: 1;
	transform: scaleX(1) translateY(0);
}

.spicy-leave-active {
	opacity: 01;
	transform: scaleX(0) translateY(100px);
	transition: all 300ms ease-in-out
}



body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}